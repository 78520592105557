import React from "react";

const ServiceCards = ({
  row,
  cardIcon,
  image,
  number,
  title,
  subtitle,
  para,
  icon,
}) => {
  return (
    <div style={{ flexDirection: row }} className="container row my-4">
      <div className="col-12 col-md-3">
        <div
          className="mx-auto"
          style={{ position: "relative", height: "16rem", width: "13rem" }}
        >
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={image}
            alt={title}
          />
          <div
            className="gredientImage"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></div>
          <div
            style={{
              height: "2.5rem",
              width: "2.5rem",
              position: "absolute",
              top: "40%",
              transform: "translate(-0%)",
              right: cardIcon,
              background: "gray",
            }}
            className="d-flex flex-column rounded-3 justify-content-center align-items-center"
          >
            {icon}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-9 row mx-auto">
        <div
          className="d-flex col-1 ms-auto align-items-center justify-content-center"
          style={{
            height: "2.5rem",
            width: "2.5rem",
            background: "var(--primaryColor)",
            color: "black",
            borderRadius: "50%",
            fontWeight: "bolder",
            fontSize: "1.3rem",
          }}
        >
          {number}
        </div>
        <div className="d-flex col-11 flex-column gap-2 text-left text-white mx-auto">
          <h3 style={{ color: "var(--primaryColor)" }}>{title}</h3>
          <h5>{subtitle}</h5>
          <p>{para}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCards;
