import React from "react";
import CTAButtons from "../../CTAButtons/CTAButtons";

const HeaderHome = () => {
  return (
    <div
      style={{ height: "65vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="d-flex flex-column justify-content-center align-items-center gap-5">
        <h1
          style={{ color: "var(--secondaryLight)", maxWidth: "95%" }}
          className="text-center mx-auto"
        >
          Building Future-Ready{" "}
          <span
            style={{
              color: "var(--primaryColor)",
              fontFamily: "TheNautigal",
              fontSize: "6rem",
            }}
          >
            Websites
          </span>{" "}
          <br />
          with Innovative Web Development Solutions
        </h1>
        <CTAButtons />
      </div>
    </div>
  );
};

export default HeaderHome;
