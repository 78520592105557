import React, { useState } from "react";
import PageHeaders from "../components/Headers/PageHeaders/PageHeaders";
import Headline from "../components/Headline/Headline";
import { MdOutlineMailOutline } from "react-icons/md";
import emailjs from "emailjs-com";
import emaiGIF from "../assets/email.gif";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [successMessage, setSuccessmassge] = useState();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_vn63yxo",
        "template_os6yz3f",
        formData,
        "ChbsOXruZ-YU_ow3K"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setLoading(false);
          setPopupMessage(
            <div className="text-center">
              <h5>Message sent successfully!</h5>
              <p>Thank You for Connecting</p>
            </div>
          );
          setSuccessmassge(true);
          setShowPopup(true);
        },
        (error) => {
          console.error("FAILED...", error);
          setLoading(false);
          setPopupMessage(
            <div className="text-center">
              <h5>Failed to send the message.</h5>
              <p>Please try again.</p>
            </div>
          );
          setSuccessmassge(false);
          setShowPopup(true);
        }
      );

    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <div>
        <PageHeaders
          title={"Creative Digital Solutions – Contact Us for Assistance"}
          message={
            "At Netario, we are dedicated to providing innovative digital solutions tailored to your needs. Whether you have questions about our web development, app development, or digital marketing services, our team is here to help. Reach out to us today, and let’s discuss how we can enhance your online presence together."
          }
        />
      </div>
      <div className="row my-5 row-gap-4 container mx-auto justify-content-between">
        <div className="col-12 gap-3 col-md-5 d-flex flex-column justify-content-center text-white">
          <Headline heading={"Contact us"} />
          <p className="ml-3">
            We’re here to help! Whether you have a question, need more
            information about our services, or want to discuss how we can
            support your business, please feel free to reach out. Send us a
            message, and our team will get back to you with the information you
            need. Your inquiries are important to us, and we’re always ready to
            connect and assist.
          </p>
          <div className="d-flex align-items-center ml-3 gap-2">
            <div
              style={{
                height: "2.5rem",
                width: "2.5rem",
                borderRadius: "5px",
                background: "var(--primaryColor)",
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <MdOutlineMailOutline className="fs-3 text-black" />
            </div>
            <div>
              <p className="m-0">Email</p>
              <p className="m-0">info@netarioinnovatives.com</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 px-5 px-md-1">
          <form
            className="row row-gap-3 border rounded-3 p-3 text-white"
            onSubmit={sendEmail}
          >
            <div className="col-12 col-md-6 d-flex flex-column">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                style={{ background: "transparent", color: "white" }}
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                placeholder="Please enter your full name"
              />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                required
                style={{ background: "transparent", color: "white" }}
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                placeholder="Please enter your email"
              />
            </div>
            <div className="col-12 d-flex flex-column">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                name="subject"
                required
                className="form-control"
                style={{ background: "transparent", color: "white" }}
                value={formData.subject}
                onChange={handleChange}
                placeholder="Please enter email subject"
              />
            </div>
            <div className="col-12 d-flex flex-column">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                required
                className="form-control"
                style={{
                  minHeight: "10rem",
                  background: "transparent",
                  color: "white",
                }}
                value={formData.message}
                onChange={handleChange}
                placeholder="Please enter your message"
              />
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-success fw-bold w-100 my-2"
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Message"}
              </button>
            </div>
          </form>
        </div>
      </div>

      {showPopup && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            background: "rgba(0,0,0,.4)",
            zIndex: "101",
          }}
          className="d-flex flex-column justify-content-center, align-items-center"
        >
          <div
            style={{
              height: "fit-content",
              width: "fit-content",
              position: "absolute",
              transform: "translate(-50%)",
              top: "35%",
              left: "50%",
              background: successMessage
                ? "var(--primaryColor)"
                : "rgba(236, 22, 22, 1)",
              zIndex: "101",
              padding: "3rem",
              borderRadius: "20px",
              color: "black",
            }}
            className="popup-overlay"
          >
            <div className="popup-content">
              <div
                style={{
                  height: "6rem",
                  width: "8rem",
                  mixBlendMode: "multiply",
                  margin: "0 auto 1rem auto",
                  overflow: "hidden",
                }}
              >
                <img
                  src={emaiGIF}
                  style={{
                    height: "120%",
                    width: "100%",
                    objectFit: "cover",
                    mixBlendMode: "multiply",
                  }}
                  alt=""
                />
              </div>

              <p>{popupMessage}</p>
              <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: ".5rem",
                  right: ".5rem",
                  fontWeight: "600",
                }}
                className="btn fs-6"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="row my-5 row-gap-5 py-5 container mx-auto justify-content-between text-white">
        <div className="col-12 col-md-7 px-4 px-md-1">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4328.386204615655!2d85.14253527609262!3d25.61755037744272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1729676753206!5m2!1sen!2sin"
            style={{
              border: 0,
              width: "100%",
              height: "100%",
              borderRadius: "15px",
            }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="col-12 col-md-4 ">
          <Headline heading={"Locate Netario"} />
          <div className="ml-3">
            <p>
              We invite you to visit our office, where our dedicated team is
              ready to assist you with your digital needs.
            </p>
            <p>
              Whether you’re looking to discuss a project, explore our services,
              or simply learn more about what we do, we’re here to help. We look
              forward to welcoming you!
            </p>
            <div className="d-flex align-items-start my-2 gap-2">
              <div
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  minWidth: "2.5rem",
                  borderRadius: "5px",
                  background: "var(--primaryColor)",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineMailOutline className="fs-3 text-black" />
              </div>
              <div>
                <p className="m-0">Phone</p>
                <p className="m-0">+91-8006384800</p>
              </div>
            </div>
            <div className="d-flex align-items-start gap-2">
              <div
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  minWidth: "2.5rem",
                  borderRadius: "5px",
                  background: "var(--primaryColor)",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineMailOutline className="fs-3 text-black" />
              </div>
              <div>
                <p className="m-0">Address</p>
                <p className="m-0">
                  202, 2nd Floor, Apoorva Radha Complex, Shri Krishna Puri,
                  Boring Road, Padtna , Bihar-800001, India
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
