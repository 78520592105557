import React from "react";
import Headline from "../Headline/Headline";
import FlowCard from "./FlowCard";

const WorkFlowSection = () => {
  return (
    <div
      style={{ color: "var(--secondaryLight)" }}
      className="container mx-auto my-5 py-5"
    >
      <Headline heading={"Our Work Flow"} />
      <h2 className=" ml-4 mr-4 mr-md-0">
        Transforming Ideas into Visual Solutions
      </h2>
      <div className="row px-3 mx-auto">
        <div className="col-12 col-md-5 p-2">
          <FlowCard
            number={"01"}
            title={"Discover"}
            para={
              "Research user needs and problems to uncover insights for design opportunities."
            }
          />
        </div>
        <div className="col-12 col-md-5 p-2">
          <FlowCard
            number={"02"}
            title={"Define"}
            para={
              "Analyze findings to identify clear objectives and scope for the design solution."
            }
          />
        </div>
        <div className="col-12 col-md-2 p-2 d-none d-md-block"></div>
      </div>
      <div className="row px-3 mx-auto">
        <div className="col-12 col-md-2 p-2  d-none d-md-block"></div>
        <div className="col-12 col-md-5 p-2">
          <FlowCard
            number={"03"}
            title={"Design"}
            para={
              "Create concepts, wireframes, and prototypes based on insights and defined goals."
            }
          />
        </div>
        <div className="col-12 col-md-5 p-2">
          <FlowCard
            number={"04"}
            title={"Deliver"}
            para={
              "Finalize, test, and implement the design, ensuring it meets user expectations and requirements."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WorkFlowSection;
