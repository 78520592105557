import React from "react";
import CTAButtons from "../../CTAButtons/CTAButtons";
import style from "./PageHeaders.module.css";

const PageHeaders = ({ title, message }) => {
  return (
    <div
      className={style.pageheader}
      style={{ maxWidth: "98%", margin: "auto" }}
    >
      <div className="d-flex container flex-column align-items-center text-white justify-content-center gap-3">
        <h1 className={style.PageHeadersStyle}>{title}</h1>
        <p className="w-75 text-center">{message}</p>
        <CTAButtons />
      </div>
    </div>
  );
};

export default PageHeaders;
