import React from "react";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Footer from "./components/Footer/Footer";
import About from "./pages/About";
import ScrollToBottomButton from "./components/ScrollToBottomButton";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Tnc from "./pages/Tnc";
import Faq from "./pages/Faq";

const App = () => {
  return (
    <div>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About_us" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Contact_us" element={<Contact />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/T&c" element={<Tnc />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
      <ScrollToBottomButton />
    </div>
  );
};

export default App;
