import React from "react";
import HeaderHome from "../components/Headers/HeaderHome/HeaderHome";
import DisplayHeader from "../components/Headers/DisplayHeader/DisplayHeader";
import ServiceSection from "../components/ServiceSection/ServiceSection";
import BrandScroller from "../components/BrandScroller/BrandScroller";
import AboutSection from "../components/AboutSection/AboutSection";
import ProjectSection from "../components/ProjectSection/ProjectSection";
import WorkFlowSection from "../components/WorkFlowSection/WorkFlowSection";
import ClientTestimonials from "../components/ClientTestimonials/ClientTestimonials";

const Home = () => {
  return (
    <div>
      <HeaderHome />
      <DisplayHeader />
      <ServiceSection />
      <BrandScroller />
      <AboutSection />
      <ProjectSection />
      <WorkFlowSection />
      <ClientTestimonials />
    </div>
  );
};

export default Home;
