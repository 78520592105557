import React, { useState } from "react";
import Logo from "../../assets/Icons/LogoFull.png";
import { MdArrowRightAlt } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { BiMenuAltLeft } from "react-icons/bi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const NavigationBar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div
      className="container-fluid"
      style={{
        position: "sticky",
        top: "0",
        zIndex: "100",
        padding: ".5rem",
        background: "var(--backgroundColor)",
      }}
    >
      <div
        style={{ margin: ".5rem auto" }}
        className="d-flex  container  justify-content-between align-items-center"
      >
        <Link to={"/"} style={{ height: "2.5rem", width: "auto" }}>
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={Logo}
            alt=""
          />
        </Link>
        <div
          className="d-md-none d-flex"
          onClick={() => setToggle((prevState) => !prevState)}
          style={{ cursor: "pointer" }}
        >
          <BiMenuAltLeft className="text-white fs-1" />
        </div>
        {toggle && (
          <div
            onClick={() => setToggle(false)}
            style={{
              position: "absolute",
              zIndex: "100",
              right: "0",
              width: "100%",
              height: "100vh",
              top: "0",
              background: "rgba(0,0,0,.5)",
            }}
            className="d-flex justify-content-end d-md-none d-flex"
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setToggle(true);
              }}
              style={{
                width: "fit-content",
                height: "100vh",
                top: "0",
                background: "var(--backgroundColor)",
                borderLeft: "1px solid rgba(256,256,256,.1)",
              }}
              className="d-flex gap-4 align-items-end p-2 px-4  flex-column"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setToggle((prevState) => !prevState);
                }}
                style={{ cursor: "pointer" }}
              >
                <BiMenuAltLeft className="text-white fs-1" />
              </div>

              <div className="d-flex flex-column gap-4">
                <NavLink
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle(false);
                  }}
                  style={{
                    textDecoration: "none",
                    color: "var(--secondaryLight)",
                  }}
                  className="d-flex align-items-center justify-content-between gap-5 w-100"
                  to={"/"}
                >
                  <HiOutlineArrowNarrowLeft /> Home
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle(false);
                  }}
                  className="d-flex align-items-center justify-content-between gap-5 w-100"
                  style={{
                    textDecoration: "none",
                    color: "var(--secondaryLight)",
                    width: "fit-content",
                  }}
                  to={"/About_us"}
                >
                  <HiOutlineArrowNarrowLeft />
                  About
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle(false);
                  }}
                  className="d-flex align-items-center justify-content-between gap-5 w-100"
                  style={{
                    textDecoration: "none",
                    color: "var(--secondaryLight)",
                    width: "fit-content",
                  }}
                  to={"/Services"}
                >
                  <HiOutlineArrowNarrowLeft />
                  Services
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle(false);
                  }}
                  className="d-flex align-items-center justify-content-between gap-5 w-100"
                  style={{
                    textDecoration: "none",
                    color: "var(--secondaryLight)",
                    width: "fit-content",
                  }}
                  to={"/Projects"}
                >
                  <HiOutlineArrowNarrowLeft />
                  Projects
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle(false);
                  }}
                  className="d-flex align-items-center justify-content-between gap-5 w-100"
                  to={"/Contact_us"}
                  style={{
                    textDecoration: "none",
                    color: "var(--secondaryLight)",
                    width: "fit-content",
                  }}
                >
                  <HiOutlineArrowNarrowLeft />
                  Contact
                </NavLink>
              </div>
            </div>
          </div>
        )}

        <div className="d-md-flex d-none align-items-center gap-4">
          <div className="d-flex align-items-center gap-4">
            <NavLink
              style={{ textDecoration: "none", color: "var(--secondaryLight)" }}
              to={"/"}
            >
              Home
            </NavLink>
            <NavLink
              style={{ textDecoration: "none", color: "var(--secondaryLight)" }}
              to={"/About_us"}
            >
              About
            </NavLink>
            <NavLink
              style={{ textDecoration: "none", color: "var(--secondaryLight)" }}
              to={"/Services"}
            >
              Services
            </NavLink>
            <NavLink
              style={{ textDecoration: "none", color: "var(--secondaryLight)" }}
              to={"/Projects"}
            >
              Projects
            </NavLink>
          </div>
          <Link
            to={"/Contact_us"}
            className="btn btn-outline-light rounded-5 py-1 ml-3 d-flex-align-items-center flex-row"
          >
            Let's Talk <MdArrowRightAlt className="ml-1 my-auto" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
