import React from "react";
import { FiArrowRight } from "react-icons/fi";
// import { MdArrowRightAlt } from "react-icons/md";
import { Link } from "react-router-dom";

const CTAButtons = () => {
  return (
    <div>
      <Link
        to={"/Contact_Us"}
        style={{ fontWeight: "700" }}
        className="btn btn-success rounded-5 ml-3 px-4 py-2 d-flex-align-items-center flex-row"
      >
        Let's Talk <FiArrowRight className="ml-1 my-auto fs-5" />
      </Link>{" "}
      <Link
        to={"/services"}
        className="btn btn-outline-light rounded-5 ml-3 px-4 py-2 d-flex-align-items-center flex-row"
      >
        Our Services{" "}
      </Link>
    </div>
  );
};

export default CTAButtons;
