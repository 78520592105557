import React from "react";
import PageHeaders from "../components/Headers/PageHeaders/PageHeaders";
import FeaturedProjects from "../components/ProjectComponents/FeaturedProjects";
import Crm from "../assets/display/Laptop.png";
import diskDefender from "../assets/display/LaptopMocup1.png";
import Ecommerce from "../assets/display/LaptopMocup2.png";
import ClientTestimonials from "../components/ClientTestimonials/ClientTestimonials";

const Projects = () => {
  const ProjectData = [
    {
      image: Crm,
      row: "row",
      heading: "HRMS & CRM Web App",
      li1: "Comprehensive Employee Management: Streamline HR tasks, from attendance tracking and payroll management to performance evaluations, all in one platform.",
      li2: "Streamlined Task Management: Assign, track, and manage tasks effectively to enhance productivity and ensure timely project completion.",
      li3: "Insightful Analytics: Access detailed reports on employee performance and task progress to support data-driven decision-making.",
    },
    {
      image: diskDefender,
      heading: "Disk Defender Software",
      row: "row-reverse",
      li1: "Data Encryption & Protection: Secure sensitive data with advanced encryption, allowing only authorized access to critical files.",
      li2: "Disk Optimization & Cleanup: Improve device performance by clearing redundant files and optimizing storage space efficiently.",
      li3: "Boost System Efficiency: Maximize disk performance with dedicated tools for storage management and resource optimization.",
    },
    {
      image: Ecommerce,
      heading: "Luxury Clothing Ecommerce Web App",
      row: "row",
      li1: "Exclusive Fashion Collection: Discover curated luxury apparel, offering premium designs and the latest high-fashion trends from top brands worldwide.",
      li2: "Personalized Shopping Experience: Enjoy tailored recommendations, virtual try-ons, and seamless browsing that cater to your unique style preferences.",
      li3: "Secure & Effortless Checkout: Experience smooth and secure transactions with multiple payment options, ensuring a premium, worry-free shopping experience.",
    },
  ];
  return (
    <div>
      <PageHeaders
        title={
          "Explore Netario's Portfolio Showcasing Our Successful Projects."
        }
        message={
          "At Netario, we take pride in our diverse portfolio, showcasing projects across various industries. Each project demonstrates our expertise in digital marketing, web development, and design. We focus on innovative solutions that enhance user experiences and deliver measurable results for our clients."
        }
      />
      <div className="container ">
        {ProjectData.map((data) => (
          <FeaturedProjects
            heading={data.heading}
            image={data.image}
            li1={data.li1}
            li2={data.li2}
            li3={data.li3}
            li4={data.li4}
            row={data.row}
          />
        ))}
      </div>

      <ClientTestimonials />
    </div>
  );
};

export default Projects;
