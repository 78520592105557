import React from 'react'

const Badge = ({input}) => {
  return (
    <div style={{height:'3rem', width:"3rem", background:'var(--grayBoxSecondary)', color:'var(--primaryColor)'}} className='d-flex rounded-1 align-items-center justify-content-center p-1'>
        {input}      
    </div>
  )
}

export default Badge
