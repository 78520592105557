import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";

const ScrollToBottomButton = () => {
  // State to handle the visibility of the buttons
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [scrollTimer, setScrollTimer] = useState(null);

  // Function to scroll to the bottom of the page
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to check the scroll position and update button visibility
  const checkScrollPosition = () => {
    const scrolledToBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    const scrolledToTop = window.scrollY <= 0;

    // Show or hide buttons based on scroll position
    setShowScrollToBottom(!scrolledToBottom);
    setShowScrollToTop(!scrolledToTop);

    // Reset the scroll timer
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }

    // Set a timeout to hide buttons if no scroll occurs in 1 second
    setScrollTimer(
      setTimeout(() => {
        setShowScrollToBottom(false);
        setShowScrollToTop(false);
      }, 1000)
    );
  };

  // Effect to handle scroll event and button visibility
  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
      if (scrollTimer) {
        clearTimeout(scrollTimer);
      }
    };
  }, [scrollTimer]);

  return (
    <>
      {showScrollToBottom && (
        <button
          style={{
            position: "fixed",
            bottom: "50%",
            right: "-3rem",
            padding: "10px 0px",
            backgroundColor: "transparent",
            color: "var(--primaryColor)",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            zIndex: 80,
            rotate: "90deg",
            display: "flex",
            letterSpacing: ".2rem",
            gap: ".5rem",
            alignItems: "center",
          }}
          onClick={scrollToBottom}
        >
          SCROLL DOWN{" "}
          <span
            className="p-2 px-3 d-flex flex-column py-1 rounded-5 ml-2"
            style={{
              border: "2px solid var(--primaryColor)",
              color: "var(--primaryColor)",
            }}
          >
            <BsArrowRight className="fs-4" />
          </span>
        </button>
      )}

      {showScrollToTop && (
        <button
          style={{
            position: "fixed",
            bottom: "50%",
            left: "-4rem",
            padding: "10px 0px",
            backgroundColor: "transparent",
            color: "var(--primaryColor)",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            zIndex: 80,
            rotate: "270deg",
            display: "flex",
            letterSpacing: ".2rem",
            gap: ".5rem",
            alignItems: "center",
          }}
          onClick={scrollToTop}
        >
          SCROLL TO TOP
          <span
            className="p-2 px-3 d-flex flex-column py-1 rounded-5 ml-2"
            style={{
              border: "2px solid var(--primaryColor)",
              color: "var(--primaryColor)",
            }}
          >
            <BsArrowRight className="fs-4" />
          </span>
        </button>
      )}
    </>
  );
};

export default ScrollToBottomButton;
