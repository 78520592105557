import React from "react";
import Headline from "../Headline/Headline";
import FounderImage from "../../assets/AboutPage/KaushalKumar.jpg";

export const Founder = () => {
  return (
    <div className="container mx-auto row-gap-4 row my-md-5 my-0 px-4 px-md-0">
      <div className="col-12 col-md-4">
        <div
          className="mx-auto"
          style={{ position: "relative", height: "15rem", width: "12rem" }}
        >
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={FounderImage}
            alt={"Kishor Kumar"}
          />
          <div
            className="gredientImage"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></div>
        </div>
      </div>
      <div className="col-12 col-md-8">
        <Headline heading={"Founder of Netario"} />
        <h2 className="ml-4 text-white">
          Meet the Founder of Netario, Mr. Kaushal Kumar
        </h2>
        <p className="ml-4 text-white">
          Kaushal Kumar is the visionary founder of Netario, a leading agency in
          digital marketing and development. With a deep passion for technology
          and design, he started the company to provide practical and innovative
          solutions in web development, app development, and graphic design.
          Under his leadership, Netario has grown into a trusted provider of
          UI/UX design and digital marketing services. Kaushal's commitment to
          quality and customer satisfaction has made Netario a reliable partner
          for businesses looking to strengthen their online presence.
        </p>
      </div>
    </div>
  );
};

export const NetarioWorks = () => {
  return (
    <div className="container mx-auto row mt-5 pt-5">
      <div className="col-12 col-md-4">
        <div
          className="mx-auto"
          style={{ position: "relative", height: "15rem", width: "12rem" }}
        >
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={FounderImage}
            alt={"Kishor Kumar"}
          />
          <div
            className="gredientImage"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></div>
        </div>
      </div>
      <div className="col-12 col-md-8">
        <Headline heading={"How Netario Works"} />
        <h2 className="ml-4 text-white">We work for Empowering Your Vision</h2>
        <p className="ml-4 text-white">
          Netario empowers your vision by providing tailored digital solutions
          that enhance your brand's online presence. With expertise in web and
          app development, UI/UX design, and digital marketing, we transform
          ideas into engaging experiences. Our collaborative approach ensures
          that we understand your unique goals, enabling us to create innovative
          strategies that drive growth. By leveraging the latest technologies
          and design trends, Netario helps you connect with your audience
          effectively and achieve your business objectives.
        </p>
      </div>
    </div>
  );
};
