import React from "react";
import PageHeaders from "../components/Headers/PageHeaders/PageHeaders";
import ServiceCards from "../components/ServiceComponents/ServiceCards";
import digital from "../assets/services/digital.png";
import development from "../assets/services/development.png";
import video from "../assets/services/vedio.png";
import Graphic from "../assets/services/Graphic.png";
import UI from "../assets/services/UI.png";
import UX from "../assets/services/UX.png";
import background from "../assets/services/servicebackground.png";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HiOutlinePaintBrush } from "react-icons/hi2";
import { IoCodeSlash } from "react-icons/io5";
import { RiMegaphoneLine } from "react-icons/ri";
import { FaRegCirclePlay } from "react-icons/fa6";

const Services = () => {
  const serviceData1 = [
    {
      image: digital,
      icon: (
        <RiMegaphoneLine
          style={{ color: "var(--primaryColor)", rotate: "-20deg" }}
          className="fs-4"
        />
      ),
      cardIcon: "-10%",
      number: "01",
      title: "Digital Marketing",
      subtitle: "Boosting your brand`s presence with digital marketing.",
      para: 'With strategic digital marketing, we help your brand grow by increasing visibility and engagement across online platforms. From SEO to social media, email campaigns to PPC, our tailored approach ensures you reach the right audience with the right message. We craft impactful campaigns that drive results, enhance your brand’s reputation, and deliver measurable success, helping your business thrive in the digital world."',
      row: "row",
    },
    {
      image: development,
      icon: (
        <IoCodeSlash
          style={{ color: "var(--primaryColor)" }}
          className="fs-4"
        />
      ),
      cardIcon: "90%",
      number: "02",
      title: "Web Development",
      subtitle: "We build your brand identiy with graphic design",
      para: "Netario offers creative and impactful graphic design services that elevate your brand identity. From eye-catching logos and engaging social media graphics to professional marketing materials, we blend creativity with strategy. Our designs communicate your brand`s essence, capturing attention and driving results, ensuring a lasting impression in the competitive marketplace.",
      row: "row-reverse",
    },
    {
      image: video,
      icon: (
        <FaRegCirclePlay
          style={{ color: "var(--primaryColor)" }}
          className="fs-4"
        />
      ),
      cardIcon: "-10%",
      number: "03",
      title: "Video Editing",
      subtitle: "We craft your brand identity through video editing.",
      para: '"Through expert video editing, we elevate your brand identity by crafting visually compelling and engaging content. From storytelling to enhancing visuals, our editing techniques ensure that your message resonates with your audience, creating a lasting impact. Whether it’s promotional videos, social media content, or brand films, we bring your vision to life, delivering professional, polished results that reflect the essence of your brand and captivate viewers.',
      row: "row",
    },
  ];

  const serviceData2 = [
    {
      image: Graphic,
      icon: (
        <HiOutlinePaintBrush
          style={{ color: "var(--primaryColor)" }}
          className="fs-4"
        />
      ),
      cardIcon: "90%",
      number: "04",
      title: "Graphic Design",
      subtitle:
        "We create attractive visuals for businesses through graphic design online ",
      para: "Netario offers creative and impactful graphic design services that elevate your brand identity. From eye-catching logos and engaging social media graphics to professional marketing materials, we blend creativity with strategy. Our designs communicate your brand`s essence, capturing attention and driving results, ensuring a lasting impression in the competitive marketplace.",
      row: "row-reverse",
    },
    {
      image: UI,
      icon: (
        <h5 style={{ color: "var(--primaryColor)" }} className="fs-5 m-auto">
          UI
        </h5>
      ),
      number: "05",
      cardIcon: "-10%",
      title: "User Interface",
      subtitle: "Transforming your brand with innovative UI design.",
      para: "With creative and user-focused UI design, we enhance your brand`s digital presence by crafting visually appealing and functional interfaces.Our designs ensure seamless interaction, making your website or app engaging and easy to navigate. By aligning aesthetics with your brand identity, we create a lasting impression that captivates users, helping you build strong connections and achieve your business goals in the digital space.",
      row: "row",
    },
    {
      image: UX,
      icon: (
        <h5 style={{ color: "var(--primaryColor)" }} className="fs-5 m-auto">
          UX
        </h5>
      ),
      number: "06",
      cardIcon: "90%",
      title: "UX Design",
      subtitle: "Crafting Seamless Experiences through User-Centric Design",
      para: "Effective UX design is about more than just aesthetics—it`s about creating a journey that feels intuitive and engaging. We focus on understanding user behavior, goals, and pain points to design solutions that enhance usability and satisfaction. By simplifying navigation and optimizing every touchpoint, we make it easier for your audience to interact with your brand. The result is a seamless, enjoyable user experience that drives engagement, boosts retention, and contributes to your business`s growth by meeting user needs with precision.",
      row: "row-reverse",
    },
  ];
  return (
    <div className=" text-center mt-5  py-3">
      <div>
        <PageHeaders
          title={
            "Netario Delivers Exceptional Services Tailored to Your Needs. "
          }
          message={
            "Founded in 2010, Netario IT has evolved into a leading digital solutions provider. With a commitment to innovation and quality, we specialize in web development, digital marketing, app development, and design, empowering businesses to thrive in the digital landscape."
          }
        />
      </div>
      <div className="container">
        {serviceData1.map((data) => (
          <ServiceCards
            cardIcon={data.cardIcon}
            icon={data.icon}
            row={data.row}
            image={data.image}
            number={data.number}
            title={data.title}
            subtitle={data.subtitle}
            para={data.para}
          />
        ))}
      </div>

      <div
        style={{
          background: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "40vh",
        }}
        className="d-flex my-5 justify-content-center"
      >
        <div className=" container d-flex gap-2 align-items-center justify-content-center text-white gap-5">
          <div>
            <h2 className="">LET’S JOIN US TO CREATE</h2>
            <h2 className="">
              <span
                className="mx-2"
                style={{ fontFamily: "TrainOne", fontSize: "3rem" }}
              >
                SOMETHING
              </span>{" "}
              GREAT
            </h2>
          </div>
          <Link to={"/Contact_us"}>
            <BsArrowUpRightCircle
              style={{ color: "var(--primaryColor)", fontSize: "3.5rem" }}
            />
          </Link>
        </div>
      </div>
      <div className="container pt-5">
        {serviceData2.map((data) => (
          <ServiceCards
            row={data.row}
            cardIcon={data.cardIcon}
            icon={data.icon}
            image={data.image}
            number={data.number}
            title={data.title}
            subtitle={data.subtitle}
            para={data.para}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
