import React from "react";
import ppStyle from "./PrivacyPolicy.module.css";

const Tnc = () => {
  return (
    <div className="container p-2 px-4 pt-5 text-white">
      <h3>Terms and Conditions</h3>
      <hr />

      <p>
        Welcome to the website of Netario Innovatives Pvt Ltd . By accessing or
        using our website and services, you agree to comply with and be bound by
        these Terms and Conditions ("Terms"). If you do not agree to these
        Terms, please refrain from using our website and services.
      </p>

      <h5>Acceptance of Terms</h5>
      <p>
        By accessing and using our website or engaging with our services,
        including but not limited to web development, digital marketing, graphic
        design, UI/UX design, and video editing, you agree to abide by these
        Terms and all applicable laws and regulations.
      </p>

      <h5>Use of the Website</h5>
      <p>
        You agree to use our website for lawful purposes only and in accordance
        with these Terms. You agree not to:
      </p>

      <ul>
        <li className={ppStyle.PrivacyPolicyLi}>
          Use the website in any way that could impair its functionality or
          interfere with any other user's use of the website.
        </li>
        <li className={ppStyle.PrivacyPolicyLi}>
          Use the website to distribute spam, viruses, or any harmful code.
        </li>
        <li className={ppStyle.PrivacyPolicyLi}>
          Attempt to gain unauthorized access to our systems or any restricted
          areas of the website.
        </li>
      </ul>
      <p>
        We reserve the right to terminate your access to our website if you
        violate any of these Terms.
      </p>

      <h5>Intellectual Property</h5>
      <p>
        All content on this website, including text, graphics, logos, images,
        and software, is the property of Netario Innovatives Pvt Ltd or its
        licensors and is protected by copyright, trademark, and other
        intellectual property laws. You may not use, copy, modify, distribute,
        or reproduce any of the materials on this website without our prior
        written consent.
      </p>

      <h5>Service5</h5>
      <p>
        We offer a variety of services, including but not limited to web
        development, digital marketing, graphic design, UI/UX design, and video
        editing. Specific terms and conditions for each service will be provided
        in the relevant service agreements.
      </p>

      <p className="fw-bold">You agree that:</p>

      <p>
        Any service provided by us will be subject to a separate agreement
        outlining the scope, payment terms, and timelines. We retain the right
        to refuse or terminate services at our discretion.
      </p>

      <h5>Payments and Refunds</h5>
      <p>
        Any payments for services provided by Netario Innovatives Pvt Ltd will
        be subject to the terms specified in the service agreement or invoice.
      </p>
      <ul>
        <li className={ppStyle.PrivacyPolicyLi}>
          <strong>Payment Terms :</strong> All invoices must be paid in full by
          the specified due date unless otherwise agreed upon.
        </li>
        <li className={ppStyle.PrivacyPolicyLi}>
          <strong>Refunds :</strong> Refunds will be considered on a
          case-by-case basis and only where the service has not been delivered
          as agreed.
        </li>
      </ul>

      <h5>Limitation of Liability</h5>
      <p>
        To the fullest extent permitted by law, Netario Innovatives Pvt Ltd and
        its affiliates, employees, or agents shall not be liable for any direct,
        indirect, incidental, special, or consequential damages that result from
        the use of or inability to use our website or services.
      </p>

      <p>
        We make no warranties or representations about the accuracy or
        completeness of the website’s content and assume no liability for any
        errors or omissions in the content.
      </p>

      <h5>Third-Party Links</h5>
      <p>
        Our website may contain links to third-party websites or services that
        are not owned or controlled by Netario Innovatives Pvt Ltd. We are not
        responsible for the content, privacy policies, or practices of any
        third-party websites. You are encouraged to review the terms and
        conditions of any third-party websites before using them.
      </p>

      <h5>Privacy Policy</h5>
      <p>
        Your use of our website and services is also governed by our Privacy
        Policy, which can be found{" "}
        <li className={ppStyle.PrivacyPolicyLi} to={"/PrivacyPolicy"}>
          Here
        </li>
        .
      </p>

      <h5>Changes to the Terms and Conditions</h5>
      <p>
        We may revise these Terms from time to time, and any updates will be
        posted on this page. Your continued use of the website and services
        after such changes constitutes your acceptance of the new Terms. We
        encourage you to review this page periodically.
      </p>

      <h5>Governing Law</h5>
      <p>
        These Terms and any disputes arising from your use of the website or
        services shall be governed by and construed in accordance with the laws
        of <b>Patna</b>, without regard to its conflict of law provisions.
      </p>

      <h5>Contact Us</h5>
      <p>
        If you have any questions or concerns about these Terms and Conditions,
        please contact us at:
      </p>

      <h4 className="mt-3">Netario Innovatives Pvt Ltd</h4>
      <p>
        <strong>Email : </strong> info@netarioinnovatives.com
      </p>
      <p>
        <strong>Phone : </strong> +91-8006384800
      </p>
      <p>
        <strong>Address : </strong> 202, 2nd Floor, Apoorva Radha Complex, Shri
        Krishna Puri, Boring Road, Padtna , Bihar-800001, India
      </p>
    </div>
  );
};

export default Tnc;
