import React from "react";
import Headline from "../Headline/Headline";
import { BsArrowUpRightCircle } from "react-icons/bs";
import mockup1 from "../../assets/display/LaptopMocup1.png";
import mockup2 from "../../assets/display/LaptopMocup2.png";
import { Link } from "react-router-dom";

const ProjectSection = () => {
  return (
    <div className="container" style={{ margin: "5rem auto" }}>
      <div className=" row " style={{ color: "var(--secondaryLight)" }}>
        <div className="col-12 col-md-5">
          <div className="d-flex align-items-center justify-content-between">
            <Headline heading={"Our Projects"} />
            <Link className="d-flex d-md-none" to={"/Projects"}>
              <BsArrowUpRightCircle
                style={{ color: "var(--primaryColor)", fontSize: "2.5rem" }}
              />
            </Link>
          </div>

          <h2 className=" ml-4 mr-4 mr-md-0">Netario is a dynamic company</h2>
        </div>
        <div className="col-12 col-md-5 p-2">
          <p className=" ml-4 mr-4 mr-md-0 text-justify">
            Netario delivers with the latest and most effective thinking, so
            we're proud of the innovative solutions that drive success for our
            clients. Our wide portfolio offers you to look at a variety of
            successful projects, from essential digital marketing campaigns to
            excellent websites and attractive apps. We work closely with each of
            our clients to better understand their requirements before creating
            complete, effective strategies that deliver excellent results.
          </p>
        </div>
        <Link
          to={"/Projects"}
          className="col-12 col-md-2 p-2 d-none d-md-flex justify-content-end"
        >
          <BsArrowUpRightCircle
            style={{ color: "var(--primaryColor)", fontSize: "3.5rem" }}
          />
        </Link>
      </div>
      <div className="d-flex align-items-center gap-3 justify-content-center">
        <div
          className="rounded-2 d-none d-sm-flex"
          style={{
            height: "19rem",
            width: "30rem",
            border: "1px solid #737373",
            overflow: "hidden",
          }}
        >
          <img
            style={{ height: "100%", width: "100%" }}
            img
            src={mockup1}
            alt=""
          />
        </div>
        <div
          className="rounded-2"
          style={{
            height: "19rem",
            width: "30rem",
            border: "1px solid #737373",
            overflow: "hidden",
          }}
        >
          <img
            style={{ height: "100%", width: "100%" }}
            img
            src={mockup2}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
