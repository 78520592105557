import React from "react";
import style from "./WorkFlowSection.module.css";

const FlowCard = ({ number, title, para }) => {
  return (
    <div className={style.borderFlow}>
      <div
        className="p-2 rounded-4 d-flex gap-2 align-items-center"
        style={{
          position: "relative",
          overflow: "hidden",
          background: "var(--backgroundColor)",
        }}
      >
        <div>
          <h6 style={{ fontSize: "5rem" }}>{number}</h6>
        </div>
        <div className="d-flex flex-column ">
          <h5 className="m-0">{title}</h5>
          <p className="m-0" style={{ fontSize: "1rem" }}>
            {" "}
            {para}
          </p>
        </div>

        <div className={style.gredientFlow}></div>
      </div>
    </div>
  );
};

export default FlowCard;
