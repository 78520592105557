import React, { useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Logo from "../../assets/Icons/Logo.png";
import { NavLink } from "react-router-dom";
import emailjs from "emailjs-com";
import emaiGIF from "../../assets/email.gif";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [successMessage, setSuccessmassge] = useState();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_vn63yxo",
        "template_u3mnicv",
        formData,
        "ChbsOXruZ-YU_ow3K"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setLoading(false);
          setPopupMessage(
            <div className="text-center">
              <h5>Subscribed successfully!</h5>
              <p>Thank You for Connecting</p>
            </div>
          );
          setSuccessmassge(true);
          setShowPopup(true);
        },
        (error) => {
          console.error("FAILED...", error);
          setLoading(false);
          setPopupMessage(
            <div className="text-center">
              <h5>Failed Subscribe.</h5>
              <p>Please try again.</p>
            </div>
          );
          setSuccessmassge(false);
          setShowPopup(true);
        }
      );

    setFormData({
      email: "",
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <hr
        style={{
          height: ".1rem",
          background: "var(--primaryColor)",
          marginTop: "5rem",
        }}
      />
      <div
        style={{ color: "white", padding: "2rem 0" }}
        className="row container mx-auto justify-content-between gap-2 px-4 px-md-0"
      >
        <div className="col-12 col-md-6 col-lg-4 d-flex text-align-center flex-column gap-4">
          <div className="d-flex flex-column gap-2 align-items-center align-items-md-start ">
            <div>
              <img style={{ height: "2rem" }} src={Logo} alt="logo" />
            </div>
            <p
              style={{ maxWidth: "90%" }}
              className="m-0 text-center text-md-start"
            >
              Netario offers digital marketing, web and app development, UI/UX
              design, and graphic design services, driving businesses' digital
              growth.
            </p>
          </div>
          {/* <div className="my-2 mb-3">
            <h5 className="text-center text-md-start">Follow us :</h5>
            <div className="d-flex align-items-center justify-content-center  justify-content-md-start  gap-4 ">
              <div
                className="rounded-5 d-flex align-items-center text-black justify-content-center"
                style={{
                  height: "1.8rem",
                  width: "1.8rem",
                  background: "var(--primaryColor)",
                }}
              >
                <FaXTwitter />
              </div>
              <div
                className="rounded-5 d-flex align-items-center text-black justify-content-center"
                style={{
                  height: "1.8rem",
                  width: "1.8rem",
                  background: "var(--primaryColor)",
                }}
              >
                <FaInstagram />
              </div>
              <div
                className="rounded-5 d-flex align-items-center text-black justify-content-center"
                style={{
                  height: "1.8rem",
                  width: "1.8rem",
                  background: "var(--primaryColor)",
                }}
              >
                <FaFacebook />
              </div>
            </div>
          </div> */}
        </div>

        <div className="col-12 col-md-6 col-lg-3 d-flex align-items-start justify-content-center">
          <div className="d-flex flex-column gap-2 w-50">
            <h6>Pages</h6>
            <div className="d-flex flex-column gap-2">
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/"}
              >
                Home
              </NavLink>
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/About_us"}
              >
                About
              </NavLink>
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/Services"}
              >
                Services
              </NavLink>
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/Projects"}
              >
                Projects
              </NavLink>
              <div></div>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 w-50">
            <h6>Quick links</h6>
            <div className="d-flex flex-column gap-2">
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/faq"}
              >
                FAQ
              </NavLink>
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/PrivacyPolicy"}
              >
                Privacy Policy
              </NavLink>
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/T&c"}
              >
                Terms & conditions
              </NavLink>
              <NavLink
                className="footerNav"
                style={{
                  textDecoration: "none",
                  color: "var(--secondaryLight)",
                  width: "fit-content",
                }}
                to={"/Contact_us"}
              >
                Contact us
              </NavLink>
              <div></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 ">
          <div
            style={{
              backgroundColor: "var(--grayBoxPrimary)",
              padding: "1rem",
              color: "var(--secondaryLight)",
              borderRadius: "8px",
            }}
            className="mx-auto d-flex flex-column gap-2 py-4"
          >
            <h6>Subscribe to get an update</h6>
            <p style={{ fontSize: ".9rem" }}>
              Stay updated with the latest trends, tips, and insights in design,
              marketing, and technology by subscribing to our newsletter!
            </p>
            <form onSubmit={sendEmail} className="d-flex flex-column gap-3">
              <input
                type="email"
                name="email"
                required
                style={{ background: "transparent", color: "white" }}
                className="rounded-5 form-control"
                value={formData.email}
                onChange={handleChange}
                placeholder="Please enter your email"
              />
              <button
                className="btn btn-success rounded-5 fw-bold"
                disabled={loading}
              >
                {loading ? "Subscribing..." : "Subscribe"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <hr
        className="container"
        style={{ height: ".1rem", background: "var(--primaryColor)" }}
      />
      <div className="container text-center text-white">
        <p>All rights reserved by Netario Innovations Private Limited</p>
      </div>
      {showPopup && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            background: "rgba(0,0,0,.4)",
            zIndex: "101",
          }}
          className="d-flex flex-column justify-content-center, align-items-center"
        >
          <div
            style={{
              height: "fit-content",
              width: "fit-content",
              position: "absolute",
              transform: "translate(-50%)",
              top: "35%",
              left: "50%",
              background: successMessage
                ? "var(--primaryColor)"
                : "rgba(236, 22, 22, 1)",
              zIndex: "101",
              padding: "3rem",
              borderRadius: "20px",
              color: "black",
            }}
            className="popup-overlay"
          >
            <div className="popup-content">
              <div
                style={{
                  height: "6rem",
                  width: "8rem",
                  mixBlendMode: "multiply",
                  margin: "0 auto 1rem auto",
                  overflow: "hidden",
                }}
              >
                <img
                  src={emaiGIF}
                  style={{
                    height: "120%",
                    width: "100%",
                    objectFit: "cover",
                    mixBlendMode: "multiply",
                  }}
                  alt=""
                />
              </div>

              <p>{popupMessage}</p>
              <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: ".5rem",
                  right: ".5rem",
                  fontWeight: "600",
                }}
                className="btn fs-6"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
