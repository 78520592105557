import React from "react";
import Logo from "../../assets/Icons/LogoShort.svg";
import "./BrandScroll.css";

const BrandScroller = () => {
  const items = Array(60).fill("Netario Innovations Private Limited");

  return (
    <div
      style={{
        overflow: "hidden",
        backgroundColor: "var(--grayBoxPrimary)",
        color: "var(--secondaryLight)",
        margin: "7rem 0",
      }}
    >
      <div
        className="scrolling-wrapper"
        style={{
          display: "flex",
          whiteSpace: "nowrap",
          animation: `scroll 20s linear infinite`,
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="d-flex align-items-center gap-1"
            style={{ display: "inline-block", padding: "0 20px" }}
          >
            <img
              style={{ height: "2.5rem", width: "2.5rem", objectFit: "cover" }}
              src={Logo}
              alt="Logo"
            />
            <h5
              className="m-0"
              style={{ whiteSpace: "nowrap", fontWeight: "400" }}
            >
              {item}
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandScroller;
