import React, { useState, useEffect } from "react";
import Headline from "../Headline/Headline";
import { BiSolidQuoteLeft } from "react-icons/bi";
// import Awara from "../../assets/Testimonials/Awara.webp";
// import Kasper from "../../assets/Testimonials/Kasper.png";
// import Medlay from "../../assets/Testimonials/Medlay.png";

const ClientTestimonials = () => {
  const testimonialData = [
    {
      // image: Medlay,
      innitaial: "M",
      nameCompany: "Medlay International Pvt. Ltd.",
      quote:
        "Netario Innovatives Pvt Ltd transformed our online presence! Their web development team delivered a stunning, user-friendly website that has significantly increased our traffic and engagement. Highly recommend!",
      name: "Md Danish , Director, Medlay International Pvt. Ltd.",
    },
    {
      // image: Kasper,
      innitaial: "K",

      nameCompany: "Kasper Infotech Pvt. Ltd.",
      quote:
        "The digital marketing strategies implemented by Netario Innovatives have been a game-changer for our business. We've seen a marked increase in leads and customer inquiries. Their team is professional, responsive, and truly understands our needs.",
      name: "Kishor Kumar , Director , Kasper Infotech Pvt. Ltd.",
    },
    {
      // image: Awara,
      innitaial: "A",
      nameCompany: "Awara Traveler",
      quote:
        "Working with Netario Innovatives on our video editing project was seamless. They listened to our vision and brought it to life in an engaging way. The final product exceeded our expectations!",
      name: "Md Junaid , Awara Traveler",
    },
  ];

  const [activeCard, setActiveCard] = useState(0);

  const handleNext = () => {
    setActiveCard((prev) => (prev + 1) % testimonialData.length);
  };

  const handlePrevious = () => {
    setActiveCard(
      (prev) => (prev - 1 + testimonialData.length) % testimonialData.length
    );
  };

  const handleDotClick = (index) => {
    setActiveCard(index);
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="container mx-auto d-flex flex-column gap-4 mb-3"
      style={{ marginTop: "5rem", color: "var(--secondaryLight)" }}
    >
      <Headline heading={"What Our Clients Say"} />

      <div className="row w-100 mx-auto align-items-center justify-content-between">
        <div style={{ position: "relative" }} className="col-12 col-md-3 ">
          {/* <img
            className="rounded-2"
            style={{ height: "15rem", width: "100%", objectFit: "contain" }}
            src={testimonialData[activeCard].image}
            alt={testimonialData[activeCard].name}
          /> */}
          <div className="d-flex m-auto w-100 flex-column align-items-center justifycontent-start">
            <h5 style={{ fontSize: "5rem", fontWeight: "bold" }}>
              {testimonialData[activeCard].innitaial}
            </h5>
            <h6 style={{ fontSize: "1.5rem", textAlign: "center" }}>
              {testimonialData[activeCard].nameCompany}
            </h6>
          </div>
          {/* <div
            className="gredientImage"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></div> */}
        </div>

        <div className="col-12 col-md-8 mx-auto">
          <div className="d-flex flex-column gap-3">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height: "3rem",
                width: "3rem",
                borderRadius: "50%",
                overflow: "hidden",
                background: "var(--primaryColor)",
              }}
            >
              <BiSolidQuoteLeft
                style={{
                  color: "var(--backgroundColor)",
                  fontSize: "1.8rem",
                }}
              />
            </div>
            <blockquote style={{ width: "100%" }}>
              {testimonialData[activeCard].quote}
            </blockquote>
            <p style={{ color: "var(--primaryColor)" }}>
              - {testimonialData[activeCard].name}
            </p>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center mx-auto gap-2">
        {testimonialData.map((_, index) => (
          <div
            key={index}
            onClick={() => handleDotClick(index)}
            style={{
              height: ".6rem",
              width: index === activeCard ? "2rem" : ".6rem",
              background: index === activeCard ? "back" : "var(--primaryColor)",
              borderRadius: index === activeCard ? ".5rem" : "50%",
              opacity: index === activeCard ? "100%" : "40%",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ClientTestimonials;
