import React from "react";
import PageHeaders from "../components/Headers/PageHeaders/PageHeaders";
import { Founder, NetarioWorks } from "../components/AboutComponents/Founder";
import Headline from "../components/Headline/Headline";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { LuGoal } from "react-icons/lu";
import { FaRegEye } from "react-icons/fa6";

const About = () => {
  return (
    <div>
      <PageHeaders
        title={"About us"}
        message={
          "Welcome to Netario Innovatives Pvt Ltd, your trusted partner in digital solutions. Established in 2024, we specialize in web development, digital marketing, graphic design, UI/UX design, and video editing. Our mission is to provide high-quality services that help businesses grow online, ensuring they stand out in the competitive digital landscape."
        }
      />
      <Founder />
      <div
        style={{ minHeight: "30vh" }}
        className="container  mx-auto row my-5 row-gap-5 py-4 align-items-center"
      >
        <div className="col-12 col-md-6">
          <Headline heading={"Our Misson"} />
          <div className="ml-4 d-flex align-items-center gap-2">
            <LuGoal
              style={{ color: "var(--primaryColor)", fontSize: "10rem" }}
            />
            <p className="mr-4 mr-md-0 text-white">
              At Netario Innovatives, our mission is to empower businesses by
              offering reliable and effective digital services. We aim to
              transform ideas into impactful online experiences that drive
              growth and engagement for our clients.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Headline heading={"Our Vision"} />
          <div className="ml-4  d-flex align-items-center gap-2">
            <FaRegEye
              style={{ color: "var(--primaryColor)", fontSize: "12rem" }}
            />
            <p className="mr-4 mr-md-0 text-white">
              Our vision at Netario Innovatives Pvt Ltd is to be a reliable
              partner for businesses worldwide, helping them succeed through
              smart and sustainable digital solutions. We believe in ongoing
              learning and using responsible practices to create positive change
              for our clients, our team, and the communities we serve.
            </p>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-column gap-3 my-5 py-3">
        <Headline heading={"Why Choose Us?"} />
        <p className="ml-4 mr-4 mr-md-0 text-white">
          Netario Innovatives Pvt Ltd is committed to delivering high-quality
          digital solutions with a focus on client needs. Our team is dedicated,
          adaptable, and always ready to go above and beyond to support our
          clients. Whether you’re a startup or an established business, we’re
          here to help you grow.
        </p>
      </div>
    </div>
  );
};

export default About;
