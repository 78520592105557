import React from "react";
import PhoneImg from "../../../assets/display/Mobile.png";
import LaptopImg from "../../../assets/display/Laptop.png";
import galaxyVideo from "../../../assets/Videos/VideoGalaxy.mp4";
import "./DisplayHeader.css";

const DisplayHeader = () => {
  return (
    <div className="pt-0 pt-md-5 " style={{ position: "relative" }}>
      <div className="videoContainer">
        <video className="displayVideoGalaxy" autoPlay muted loop>
          <source src={galaxyVideo} type="video/mp4" />
          <source src={galaxyVideo} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        <div className="blendOverlay"></div>
      </div>
      <div className="d-flex align-items-center justify-content-center gap-5 mt-5 pt-5">
        <div
          className="rounded-4 d-none d-md-flex border border-light"
          style={{
            height: "28.24rem",
            width: "20.88rem",
            background: "#1B1B1B",
          }}
        >
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={PhoneImg}
            alt=""
          />
        </div>
        <div
          className=" laptopScreen rounded-4 border border-light"
          style={{
            height: "28.42rem",
            width: "45.97rem",
            background: "#1B1B1B",
          }}
        >
          <img
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={LaptopImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayHeader;
