import React from "react";
import Headline from "../Headline/Headline";
import FeatureStyles from "./FeaturedProjects.module.css";

const FeaturedProjects = ({ row, heading, image, li1, li2, li3, li4 }) => {
  return (
    <div
      style={{ flexDirection: row }}
      className="my-5 py-3 row-gap-3 row px-4 px-md-0"
    >
      <div className="col-12 col-md-4">
        <div
          className="mx-auto  rounded-3"
          style={{
            position: "relative",
            height: "16rem",
            width: "22rem",
            overflow: "hidden",
            border: "1px solid rgba(256,256,256, .3)",
          }}
        >
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={image}
            alt={heading}
          />
          <div
            className="gredientImage"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></div>
        </div>
      </div>
      <div className="col-12 col-md-8 d-flex flex-column justify-content-center">
        <Headline heading={heading} />
        <ul className="d-flex flex-column gap-3">
          <li className={FeatureStyles.FeatureList}>{li1}</li>
          <li className={FeatureStyles.FeatureList}>{li2}</li>
          <li className={FeatureStyles.FeatureList}>{li3}</li>
        </ul>
      </div>
    </div>
  );
};

export default FeaturedProjects;
