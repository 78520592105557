import React from "react";
import { BsArrowUpRightCircle } from "react-icons/bs";
import Card from "../Card/Card";
import Headline from "../Headline/Headline";
import { TbBrush } from "react-icons/tb";
import { IoCodeSlash } from "react-icons/io5";
import { MdOutlinePlayCircleOutline } from "react-icons/md";
import { GiMegaphone } from "react-icons/gi";
import { Link } from "react-router-dom";
import styles from "./ServiceSection.module.css";

const ServiceSection = () => {
  const ServiceCard = [
    {
      icon: <GiMegaphone className="fs-4" />,
      title: "Digital Marketing",
      para: "Expand your reach with our comprehensive online marketing  solutions. We work using SEO, social media strategies, and targeted advertising to touch your target audience of ideal customers and deliver measurable results.",
    },
    {
      icon: <IoCodeSlash className="fs-4" />,
      title: "Website Solutions",
      para: "Take advantage of our services for web development  to build an effective online presence. Our company discovers attractive, user-friendly, responsive websites that function effectively on all platforms.",
    },
    {
      icon: <MdOutlinePlayCircleOutline className="fs-4" />,
      title: "Video Editing",
      para: "Communicate with a meaningful story and attractive video. Our platform's expert video editors can turn your words into high-quality video effects  so you can get the word about your brand to worldwide viewers.",
    },
    {
      icon: <span className="fs-5">UX</span>,
      title: "UX Experience",
      para: "Improve user interest by using our active user interface design. We can offer user-friendly interfaces that are both visually impressive and clear. Hence, we design platforms that are not only easy to use but also try to meet the needs of your target audience so that every interaction is user-friendly and fulfilling. Our UX design technique, which includes user testing, in-depth research, and iterative design, guarantees natural, user-friendly experiences.",
    },
    {
      icon: <span className="fs-5">UI</span>,
      title: "User Interface",
      para: "Improve user interest by using our active user interface design. We can offer user-friendly interfaces that are both visually impressive and clear. Hence, we design platforms that are not only easy to use but also try to meet the needs of your target audience so that every interaction is user-friendly and fulfilling.",
    },
    {
      icon: <TbBrush className="fs-4" />,
      title: "Graphic Design",
      para: "We have talented designers who can Create the best graphic design online  to make your audience feel connected to your brand and strengthen your brand identity. From logos to marketing materials, we always make sure the visuals are professional and engaging.",
    },
  ];

  return (
    <div
      style={{ marginTop: "5rem", position: "relative" }}
      className="container mx-auto d-flex flex-column gap-2"
    >
      <div className="d-flex align-items-center justify-content-between">
        <Headline heading={"Our Popular Services"} />
        <Link className="d-flex d-md-none" to={"/Services"}>
          <BsArrowUpRightCircle
            className={styles.CircleArrow}
            style={{ color: "var(--primaryColor)", fontSize: "2.5rem" }}
          />
        </Link>
      </div>
      <div className="d-flex align-item-start justify-content-between">
        <p
          style={{ color: "var(--secondaryLight)", fontWeight: "300" }}
          className=" ml-4 mr-4 mr-md-5 text-justify"
        >
          At Netario, we offer a suite of services designed to boost your
          brand's visibility and drive success. Our expertise includes Digital
          Marketing to amplify your reach, UI Designing for intuitive user
          experience, and Website Designing to create engaging, responsive sites
          that attract audiences. Discover how we can elevate your brand today!
        </p>
        <Link className="d-none d-md-flex" to={"/Services"}>
          <BsArrowUpRightCircle
            style={{ color: "var(--primaryColor)", fontSize: "3.5rem" }}
          />
        </Link>
      </div>
      <div className=" container d-flex col-gap-4 row-gap-4 flex-wrap align-items-center justify-content-between">
        {ServiceCard.map((cardData, index) => (
          <Card
            Icon={cardData.icon}
            title={cardData.title}
            para={cardData.para}
          />
        ))}
      </div>
    </div>
  );
};

export default ServiceSection;
