import React from "react";
import Badge from "./Badge";

const Card = ({ title, Icon, para }) => {
  const trimmedContent = (trimData) => {
    return (
      trimData.toString().slice(0, 240) + (trimData.length > 240 ? "..." : "")
    );
  };
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div
        style={{
          backgroundColor: "var(--grayBoxPrimary)",
          height: "15rem",
          width: "100%",
          color: "var(--secondaryLight)",
        }}
        className="p-3 p rounded-1 d-flex flex-column gap-2 "
      >
        <Badge input={Icon} />
        <h3 className="m-0">{title}</h3>
        <p className="m-0" style={{ fontWeight: "300" }}>
          {trimmedContent(para)}
        </p>
      </div>
    </div>
  );
};

export default Card;
