import React, { useState } from "react";
import PageHeaders from "../components/Headers/PageHeaders/PageHeaders";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import faqImg from "../assets/Faq/Faq.png";
import ppStyle from "./PrivacyPolicy.module.css";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "What services does Netario Innovatives Pvt Ltd offer?",
      answer: (
        <div>
          <p>We provide a wide range of services, including:</p>
          <ul>
            <li className={ppStyle.PrivacyPolicyLi}>
              Web Development (custom websites, web applications)
            </li>
            <li className={ppStyle.PrivacyPolicyLi}>
              Digital Marketing (SEO, PPC, social media management, content
              marketing)
            </li>
            <li className={ppStyle.PrivacyPolicyLi}>
              Graphic Design (branding, logo design, print media)
            </li>
            <li className={ppStyle.PrivacyPolicyLi}>
              UI/UX Design (user interface design, user experience strategy)
            </li>
            <li className={ppStyle.PrivacyPolicyLi}>
              Video Editing (corporate videos, promotional content, editing
              services)
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: "How do I get started with your services?",
      answer:
        "You can start by contacting us through our website or sending an email to [your email address]. We’ll schedule an initial consultation to understand your project requirements and provide a custom quote.",
    },
    {
      question: "Do you offer packages for small businesses?",
      answer:
        "Yes, we offer tailored packages for businesses of all sizes, including startups and small businesses. Our packages are flexible to meet your budget and specific needs.",
    },
    {
      question: "How long does it take to build a website?",
      answer:
        "The timeline depends on the complexity and scope of the project. A simple website can take 4-6 weeks, while a more complex project may take several months. We will provide an estimated timeline after the project scoping.",
    },
    {
      question: "How do you approach SEO?",
      answer:
        "We follow a comprehensive approach to SEO that includes keyword research, on-page optimization, technical SEO audits, and high-quality backlink building. Our goal is to improve your website’s visibility on search engines and drive organic traffic.",
    },
    {
      question: "Can you redesign my existing branding?",
      answer:
        "Absolutely! We specialize in both designing new brands and refreshing existing ones. We’ll work with you to understand your brand identity and create a modern, cohesive look.",
    },
    {
      question: "What is the difference between UI and UX design?",
      answer:
        "Our UI/UX design process begins with research and wireframing, followed by prototyping and testing. We work closely with clients to ensure that the final product aligns with user needs and business goals.",
    },
    {
      question: "How long does video editing take?",
      answer:
        "The duration depends on the project’s complexity and length. Typically, short promotional videos take around 1-2 weeks to edit, while more complex projects may take longer. We will provide a timeline after reviewing your requirements.",
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <PageHeaders
        title={"You’ve Got Questions? We’ve Got Answers!"}
        message={
          "Find answers to common questions about Netario’s digital marketing, web development, app development, and UI/UX design services. Learn more about our processes, pricing, and how we can help you succeed."
        }
      />
      <div className="container mx-auto row">
        <div className="faq-container col-12 col-md-7">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question d-flex align-items-center justify-content-between"
                onClick={() => handleToggle(index)}
              >
                {item.question}{" "}
                {openIndex === index ? (
                  <FiMinusCircle className="fs-5" style={{ color: "white" }} />
                ) : (
                  <FiPlusCircle
                    className="fs-5"
                    style={{ color: "var(--primaryColor)" }}
                  />
                )}
              </div>
              <div
                style={{ color: "rgba(256,256,256,.5)" }}
                className={`faq-answer ${
                  openIndex === index ? "open" : ""
                } ml-3 my-1`}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
        <div className="col-12 col-md-4">
          <img style={{ height: "60vh", width: "100%" }} src={faqImg} alt="" />
        </div>
      </div>

      <style jsx>{`
        .faq-container {
          margin: 20px;
        }
        .faq-item {
          border-radius: 5px;
          margin-bottom: 10px;
          overflow: hidden;
          color: white;
        }
        .faq-question {
          padding: 15px;
          background-color: var(--backgroundColor);
          cursor: pointer;
          border-bottom: 1px solid rgba(77, 77, 77, 0.542);
        }
        .faq-answer {
          max-height: 0;
          overflow: hidden;
          opacity: 1;
          transition: max-height 1s ease;
        }
        .faq-answer.open {
          max-height: 300px; /* Adjust based on content */
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

export default Faq;
