import React from 'react'
import style from "./Headline.module.css"

const Headline = ({heading}) => {
  return (
    <div className='container-fluid'> 
      <h2 className={style.heading}>{heading}</h2>
    </div>
  )
}

export default Headline
