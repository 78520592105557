import React from "react";
import AboutImg1 from "../../assets/AboutPage/AboutImg1.png";
import AboutImg2 from "../../assets/AboutPage/AboutImg2.png";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import Headline from "../Headline/Headline";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--grayBoxPrimary)",
        color: "var(--secondaryLight)",
      }}
      className="d-flex  p-1 gap-1 align-items-center py-5"
    >
      <div className="container row-reverse  row mx-auto ">
        <div className="col-12 col-md-6 order-2 order-md-1 d-flex align-items-center justify-content-center gap-3 p-2">
          <div style={{ height: "20rem", width: "14rem" }}>
            <img
              style={{ height: "100%", width: "100%" }}
              src={AboutImg1}
              alt="img"
            />
          </div>
          <div
            style={{
              position: "relative",
              height: "20rem",
              width: "14rem",
              marginTop: "4rem",
            }}
          >
            <img
              style={{ height: "100%", width: "100%" }}
              src={AboutImg2}
              alt="img"
            />
            <Link
              to={"/About_us"}
              style={{
                position: "absolute",
                bottom: "-1rem",
                right: "-1rem",
                color: "var(--primaryColor)",
                fontSize: "3.5rem",
              }}
            >
              <BsArrowUpRightCircleFill
                style={{
                  border: "4px solid var(--grayBoxPrimary)",
                  borderRadius: "50%",
                }}
              />
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-6 order-1 order-md-2 d-flex flex-column gap-3 p-2 justify-content-center">
          <Headline heading={"About Netario"} />
          <h2 className=" ml-4 mr-4 mr-md-5">
            Helping Brands Succeed with Smart Solutions
          </h2>
          <p
            className=" ml-4 mr-4 mr-md-5 text-justify"
            style={{ fontWeight: "300" }}
          >
            At Netario, we offer talented digital solutions that help businesses
            succeed in a competitive world. The services include graphic design,
            web development, digital marketing, video editing, and UI/UX design.
            We work with a professional, goal-oriented team that uses a creative
            and technically proficient approach to help you build strong
            relationships with your target audience and enhance your brand. We
            ensure that our solutions meet your specific demands by listening to
            and paying attention to your distinct vision and goals.
          </p>
          <p
            className=" ml-4 mr-4 mr-md-5 text-justify"
            style={{ fontWeight: "300" }}
          >
            Whether you are a start-up looking to position yourself and your
            venture on the internet or an established brand that aims to enhance
            its digital strategy, Netario takes materializing your dreams
            seriously.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
