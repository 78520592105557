import React from "react";
import ppStyle from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className="container p-2 px-4 pt-5 text-white">
      <h3>Privacy Policy</h3>
      <hr />
      <p>
        At <b>Netario Innovatives Pvt Ltd</b> , your privacy is of utmost
        importance to us. This Privacy Policy outlines how we collect, use,
        disclose, and protect your personal information when you interact with
        us, specifically through submitting your details or subscribing to our
        newsletter.
      </p>
      <p>
        By engaging with our website or services, you agree to the practices
        described in this policy.
      </p>
      <div>
        <h5>Information We Collect</h5>
        <p>
          We collect the following personal information only when you actively
          provide it:
        </p>
        <ul>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Name :</strong> Collected when you submit an inquiry or
            subscribe to our newsletter.
          </li>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Email Address :</strong> Collected when you reach out to us
            via email or subscribe to our newsletter.
          </li>
        </ul>
        <p>
          We do not collect any other personal information unless explicitly
          provided by you.
        </p>
      </div>
      <div>
        <h5>How We Use Your Information</h5>
        <p>
          The information we collect is used solely for the following purposes:
        </p>
        <ul>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Communication : </strong> To respond to your inquiries or
            any messages you send to us.
          </li>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Newsletter :</strong> To send you updates and information if
            you have subscribed to our newsletter.
          </li>
        </ul>
        <p>
          We do not use your information for any other purposes, and we will not
          send you marketing material unless you have explicitly opted into it.
        </p>
      </div>
      <div>
        <h5>Information Sharing and Disclosure</h5>
        <p>
          We do not share, sell, or rent your personal information to any third
          parties, except in the following circumstances:
        </p>
        <ul>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Service Providers :</strong> We may share your information
            with third-party service providers who assist us in operating our
            website or managing our newsletter. These providers are required to
            maintain the confidentiality of your information.
          </li>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Legal Obligations :</strong> We may disclose your
            information if required to do so by law or in response to valid
            legal requests.
          </li>
        </ul>
      </div>
      <div>
        <h5>Data Security</h5>
        <p>
          We take reasonable steps to protect your personal information from
          unauthorized access, use, or disclosure. However, no internet-based
          system is completely secure, and we cannot guarantee the security of
          your data transmitted through our website.
        </p>
      </div>
      <div>
        <h5>Your Rights</h5>
        <p>You have the right to:</p>
        <ul>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Access :</strong> Request access to the personal information
            we hold about you.
          </li>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Correction :</strong> Request that we update or correct your
            personal information if it is inaccurate or incomplete.
          </li>
          <li className={ppStyle.PrivacyPolicyLi}>
            <strong>Unsubscribe :</strong> Opt-out of receiving our newsletter
            at any time by following the unsubscribe link in the email.
          </li>
        </ul>
      </div>
      <div>
        <h5>Third-Party Links</h5>
        <p>
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices or content of these external
          sites, and we encourage you to review their privacy policies.
        </p>
      </div>
      <div>
        <h5>Changes to This Privacy Policy</h5>
        <p>
          We may update this Privacy Policy from time to time. Changes will be
          posted on this page, and we encourage you to review the policy
          periodically.
        </p>
      </div>
      <div>
        <h5>Contact Us</h5>
        <p>
          If you have any questions or concerns regarding this Privacy Policy or
          wish to manage your data, please contact us at:
        </p>
        <h4 className="mt-3">Netario Innovatives Pvt Ltd</h4>
        <p>
          <strong>Email : </strong> info@netarioinnovatives.com
        </p>
        <p>
          <strong>Phone : </strong> +91-8006384800
        </p>
        <p>
          <strong>Address : </strong> 202, 2nd Floor, Apoorva Radha Complex,
          Shri Krishna Puri, Boring Road, Padtna , Bihar-800001, India
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
